import React, { useState } from "react";
import { Space, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./UsersTableHeader.scss";

export interface IUsersTableHeaderProps {
  searchText?: string;
  onSearch?: (value: string) => void;
}

const UsersTableHeader: React.FC<IUsersTableHeaderProps> = props => {
  const [searchText, setSearchText] = useState<string>(props.searchText ?? "");

  const search = (text: string) => {
    if (props.onSearch) {
      props.onSearch(text);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;

    setSearchText(searchText);

    if (event.type === "click") {
      search(searchText);
    }
  };

  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;

    if (key === "Enter") {
      search(searchText);
    }
  };

  const handleSearchClick = () => {
    search(searchText);
  };

  return (
    <Space>
      <h2>Users</h2>
      <Input
        className="TableHeader__SearchInput"
        placeholder="Search by Name, Email, or MDM ID"
        prefix={(
          <Button type="text" className="TableHeader__SearchButton" onClick={handleSearchClick}>
            <SearchOutlined />
          </Button>
        )}
        value={searchText}
        allowClear
        onChange={handleSearchChange}
        onKeyDown={handleSearchKeyDown}
      />
    </Space>
  );
};

export default UsersTableHeader;
