import React from "react";
import { Descriptions } from "antd";
import { get } from "lodash";
import { IMappedUserFieldOptions, mappedUserFields } from "@app/common/userFields";
import { flattenObjectKeys, formatDateString, formatDateTimeString } from "@app/utils";
import { useUser } from "../useUser";
import "./UserDetails.scss";

const UserDetails: React.FC = () => {
  const { user } = useUser();

  const formData = flattenObjectKeys(user);

  const renderField = (field: IMappedUserFieldOptions) => {
    const { type, options } = field;
    const value = field.mapper ? field.mapper(user) : get(formData, field.name);
    let valueText;

    if (type === "date" && value) {
      valueText = formatDateString(new Date(value));
    } else if (type === "datetime" && value) {
      valueText = formatDateTimeString(new Date(value));
    } else if (type === "select" && options) {
      valueText = options?.find(option => value === (option.value ?? option.text))?.text;
    } else {
      valueText = value;
    }

    if (!valueText) {
      return field.placeholder ?? "-";
    }

    return valueText;
  };

  return (
    <div className="user-details__container">
      {mappedUserFields.map(({ group, fields }) => (
        <div className="descriptions__container" key={group}>
          <Descriptions title={group} layout="vertical" bordered>
            {fields.map(
              field =>
                !field.hideOnDetailsView && (
                  <Descriptions.Item key={field.name} label={field.label}>
                    {renderField(field)}
                  </Descriptions.Item>
                )
            )}
          </Descriptions>
        </div>
      ))}
    </div>
  );
};

export default UserDetails;
