import { useEffect, useRef } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useAuth } from "@app/hooks";
import { storageKeys } from "@app/common/constants";
import { hasTokenExpired } from "@app/utils";

export const useFetch = <TRequestParams>(
  callback: (requestParams: TRequestParams) => AxiosRequestConfig,
  validateToken: boolean = true
): <TResponseData>(requestParams: TRequestParams) => Promise<TResponseData> => {
  const { login } = useAuth();
  const controllerRef = useRef<AbortController>();

  useEffect(() => {
    return () => {
      const controller = controllerRef.current;

      if (controller) {
        controller.abort();
      }
    };
  }, []);

  return async <TResponseData>(requestParams: TRequestParams): Promise<TResponseData> => {
    if (validateToken) {
      const isTokenExpired = hasTokenExpired();

      if (isTokenExpired) {
        login(true);
        return Promise.reject(Error("Token expired"));
      }
    }

    const controller = new AbortController();

    controllerRef.current = controller;

    try {
      const fetchInit = callback(requestParams);
      const { url, headers, ...rest } = fetchInit;

      const requestUrl = `${process.env.API_BASE_URL}${url}`;

      const defaultHeaders = {
        "Content-Type": "application/json"
      };

      let authHeaders;
      const tokenId = localStorage.getItem(storageKeys.TOKEN_ID);

      if (tokenId) {
        authHeaders = {
          Authorization: `Bearer ${tokenId}`
        };
      }

      const requestConfig = {
        signal: controller.signal,
        headers: {
          ...defaultHeaders,
          ...authHeaders,
          ...headers
        },
        ...rest
      } as AxiosRequestConfig;

      const response = await axios(requestUrl, requestConfig);

      if (response.data.token) {
        localStorage.setItem(storageKeys.TOKEN_ID, response.data.token);
      }

      if (response?.data?.error) {
        throw Error(response?.data?.error);
      }

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data.error) {
        throw Error(error.response.data.error);
      } else {
        throw error;
      }
    } finally {
      controllerRef.current = undefined;
    }
  };
};
