import React from "react";
import { Result, Button } from "antd";
import { LOGIN_URI } from "@app/common/constants";
import "./LogoutPage.scss";

const LogoutPage: React.FC = () => {
  return (
    <Result
      status="success"
      title="You've been logged out!"
      subTitle="You have successfully logged out of your account."
      className="ap-logout-page"
      extra={[
        <Button type="primary" key="login" href={LOGIN_URI}>
          Go to Login Page
        </Button>
      ]}
    />
  );
};

export default LogoutPage;
