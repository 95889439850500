import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "@app/App";
import { routes } from "@app/common/routes";
import { AppPage, ProtectedRoute, UserConsents, UserDetails } from "@app/components";
import { LogoutPage, NotFoundPage, UsersPage, UserPage, LoginStatusPage, UserEditPage } from "@app/pages";

export const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Navigate to={`/${routes.users}`} replace />
      },
      {
        element: (
          <ProtectedRoute>
            <AppPage />
          </ProtectedRoute>
        ),
        children: [
          {
            path: routes.users,
            element: <UsersPage />
          },
          {
            path: `/${routes.users}/:uid`,
            element: <UserPage />,
            children: [
              {
                path: routes.userDetails.consents,
                element: <UserConsents />
              },
              {
                path: "",
                element: <UserDetails />
              }
            ]
          },
          {
            path: `/${routes.users}/:uid/${routes.userDetails.edit}`,
            element: <UserEditPage />
          }
        ]
      },
      {
        element: <AppPage />,
        children: [
          {
            path: routes.loginStatus,
            element: <LoginStatusPage />
          }
        ]
      },
      {
        path: routes.logout,
        element: <LogoutPage />
      },
      {
        element: <AppPage />,
        children: [
          {
            path: "*",
            element: <NotFoundPage />
          }
        ]
      }
    ]
  }
]);
