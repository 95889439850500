import React, { useState } from "react";
import { ILocalConsent } from "@app/interfaces";
import { useUser } from "../useUser";
import UserConsentHistoryPopup from "./UserConsentHistoryPopup";
import ConsentUpdatePopup from "./UserConsentUpdatePopup";
import UserConsentSection from "./UserConsentSection";
import { getConsentsByChannelType } from "./UserConsents.utils";
import "./UserConsents.scss";

const UserConsent: React.FC = () => {
  const { user, userConsents, onConsentUpdated } = useUser();
  const [selectedConsent, setSelectedConsent] = useState<ILocalConsent | null>(null);
  const [isConsentHistoryPopupOpen, setIsConsentHistoryPopupOpen] = useState(false);
  const [isConsentUpdatePopupOpen, setIsConsentUpdatePopupOpen] = useState(false);

  const handleConsentHistoryPopupClose = () => {
    setIsConsentHistoryPopupOpen(false);
  };

  const handleConsentUpdatePopupClose = () => {
    setIsConsentUpdatePopupOpen(false);
  };

  const handleConsentUpdateClick = (consent: ILocalConsent) => () => {
    setSelectedConsent(consent);
    setIsConsentUpdatePopupOpen(true);
  };

  const handleConsentHistoryClick = (consent: ILocalConsent) => () => {
    setSelectedConsent(consent);
    setIsConsentHistoryPopupOpen(true);
  };

  const consentsByChannelType = getConsentsByChannelType(userConsents, user);

  return (
    <>
      <div className="UserConsents">
        {!userConsents && (
          <div>
            Impossible to capture consents: no MDMID is found in user profile.<br />
            Please contact support.
          </div>
        )}
        {userConsents && !userConsents?.channels && (
          <div>
            Impossible to capture consents: no allowed channels found in user profile.<br />
            Please contact support.
          </div>
        )}

        {consentsByChannelType?.map(({ title, consents }) => {
          return (
            <UserConsentSection
              key={title}
              title={title}
              consents={consents}
              user={user}
              onConsentUpdateClick={handleConsentUpdateClick}
              onConsentHistoryClick={handleConsentHistoryClick}
            />
          );
        })}
      </div>
      {selectedConsent && (
        <>
          <UserConsentHistoryPopup
            user={user}
            consent={selectedConsent}
            opened={isConsentHistoryPopupOpen}
            onClose={handleConsentHistoryPopupClose}
          />
          <ConsentUpdatePopup
            user={user}
            countryCode={user.systemData.countryCode ?? ""}
            consent={selectedConsent}
            showCapture={selectedConsent.isShowCapture ?? false}
            opened={isConsentUpdatePopupOpen}
            onClose={handleConsentUpdatePopupClose}
            onConsentUpdated={onConsentUpdated}
          />
        </>
      )}
    </>
  );
};

export default UserConsent;
