import { ChannelTypes } from "@app/interfaces";

interface IConsentChannelInfo {
  title: string;
  description: string;
}

const channelsInfo: Record<string, IConsentChannelInfo> = {
  [ChannelTypes.EMAIL]: {
    title: "Email Communication",
    description: "Email Communication"
  },
  [ChannelTypes.POSTAL]: {
    title: "Postal Mail",
    description: "Postal Mail"
  },
  [ChannelTypes.TRANSFER_OF_VALUE]: {
    title: "EFPIA",
    description: "EFPIA"
  },
  [ChannelTypes.PHONE]: {
    title: "Phone Calls",
    description: "Landline Phone"
  },
  [ChannelTypes.SMS]: {
    title: "Mobile",
    description: "Mobile"
  },
  [ChannelTypes.FAX]: {
    title: "Fax",
    description: "Fax"
  }
};

export const getConsentChannelInfo = (channelType: string): IConsentChannelInfo => {
  const channelInfo = channelsInfo[channelType];

  return channelInfo ?? {
    title: channelType,
    description: channelType
  };
};
