import React, { useEffect, useContext, useState } from "react";
import { Result, Button } from "antd";
import { storageKeys } from "@app/common/constants";
import { AppContext } from "@app/store";
import { Loader } from "@app/components";
import * as api from "@app/services/api";
import { useFetch, useAuth } from "@app/hooks";
import { hasTokenExpired, getErrorMessage } from "@app/utils";

export interface IProtectedRouteProps extends React.PropsWithChildren {
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = props => {
  const { login, logout } = useAuth();
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(!appContext.permissions);
  const getPermissions = useFetch<void>(api.getPermissions);
  const [getPermissionsError, setGetPermissionsError] = useState<string>("");

  const isTokenExpired = hasTokenExpired();

  const fetchPermissions = async() => {
    setLoading(true);

    const handleError = (error: unknown) => {
      const errorMessage = getErrorMessage(error);

      console.error("Unable to load permissions:", errorMessage);
      setGetPermissionsError(errorMessage);
    };

    try {
      const response = await getPermissions<api.IGetPermissionsResponse>();
      const permissions = response.data;

      appContext.setPermissions(permissions);

      const availableFlows = permissions.availableFlows ?? [];

      if (availableFlows.length === 0) {
        handleError("Permissions do not have any available flow");
      }

      const selectedFlowId = localStorage.getItem(storageKeys.SELECTED_FLOW_ID);
      const activeFlow =
        availableFlows.find(availableFlow => availableFlow.id === selectedFlowId) ||
        permissions.defaultFlow ||
        availableFlows[0];

      appContext.setActiveFlow(activeFlow);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isTokenExpired) {
      login(true);
    }
  }, [isTokenExpired]);

  useEffect(() => {
    if (!isTokenExpired && !appContext.permissions) {
      void fetchPermissions();
    }
  }, [isTokenExpired, appContext.permissions]);

  const handleLogout = () => {
    logout(true);
  };

  if (isTokenExpired) {
    return (
      <Loader>
        Redirecting to login ...
      </Loader>
    );
  }

  if (loading) {
    return (
      <Loader>
        Loading permissions ...
      </Loader>
    );
  }

  if (getPermissionsError) {
    return (
      <Result
        status="500"
        title={getPermissionsError}
        extra={(
          <Button type="primary" onClick={handleLogout}>
            Back to login
          </Button>
        )}
      />
    );
  }

  const isUserActive = appContext.permissions?.isActive ?? false;
  const isUserHasAvailableFlows =
    appContext.permissions?.availableFlows &&
    appContext.permissions.availableFlows.length > 0 &&
    appContext.permissions.defaultFlow?.permissions;

  if (!isUserActive || !isUserHasAvailableFlows) {
    return (
      <Result
        status="500"
        title="You do not have permission to view any country. Please reach out to your local agent manager."
        extra={(
          <Button type="primary" onClick={handleLogout}>
            Back to login
          </Button>
        )}
      />
    );
  }

  return props.children;
};

export default ProtectedRoute;
