import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import { MDSConfigProvider } from "@mds/merck-design-system";
import { ErrorBoundaryFallback } from "@app/components";
import { AppContextProvider } from "@app/store";

import "normalize.css/normalize.css";
import "@mds/merck-design-system/dist/fonts.module.css";

const App = () => {
  return (
    <AppContextProvider>
      <MDSConfigProvider
        hashed={true}
        theme="merckLightTheme"
      >
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <Outlet />
        </ErrorBoundary>
      </MDSConfigProvider>
    </AppContextProvider>
  );
};

export default App;
