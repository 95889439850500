import { AxiosRequestConfig } from "axios";
import { REDIRECT_URI } from "@app/common/constants";
import { API_URL } from "../apiUrl";

export interface IGetLoginStatusRequest {
  params: {
    authCode: string;
  };
}

export interface IGetLoginStatusResponse {
  access_token: string;
  id_token: string;
}

export const getLoginStatus = ({ params }: IGetLoginStatusRequest): AxiosRequestConfig => {
  return {
    url: API_URL.LOGIN_STATUS,
    params: {
      ...params,
      redirectUri: location.origin.includes("localhost") ? undefined : REDIRECT_URI
    },
    method: "GET",
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    }
  };
};
