import React from "react";
import { Checkbox, Table, Button } from "antd";
import { type ISapAccount } from "@msd-cex/sap-cdc-shared";
import { ILocalConsent } from "@app/interfaces";
import { formatDateTimeString } from "@app/utils";
import { usePermissions } from "@app/hooks";
import { permissions } from "@app/common/constants";
import "./Consent.scss";

interface IConsentProps extends React.PropsWithChildren {
  user: ISapAccount;
  consent: ILocalConsent;
  onUpdate: (consent: ILocalConsent) => void;
  onShowHistory: (consent: ILocalConsent) => void;
}

const renderDisabledCell = (text: string) => (
  <span className="Consent__DisabledCell">
    {text}
  </span>
);

const columns = [
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
    render: renderDisabledCell
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    key: "timestamp",
    render: renderDisabledCell
  }
];

const Consent: React.FC<IConsentProps> = props => {
  const { user, consent, onUpdate, onShowHistory } = props;

  const { hasPermission } = usePermissions();
  const isConsentUpdatePermission = hasPermission(permissions.CONSENT_UPDATE);
  const isConsentHistoryPermission = hasPermission(permissions.CONSENT_HISTORY);

  const isShowHistory = consent.history.length > 0;

  const timestamp = formatDateTimeString(consent.timestamp ?? user.systemData.created, "-");
  const dataSource = [
    {
      key: "1",
      source: consent.updatedBy,
      timestamp: timestamp
    }
  ];

  let dataColumns;

  if (!consent.source) {
    dataColumns = columns.filter(column => column.key !== "source");
  } else {
    dataColumns = columns;
  }

  const handleUpdateClick = () => {
    onUpdate(consent);
  };

  const handleShowHistoryClick = () => {
    onShowHistory(consent);
  };

  return (
    <div className="Consent">
      <div>
        <div className="Consent__checkboxes">
          <Checkbox defaultChecked={consent.optIn} disabled>
            {consent.channelValue}
          </Checkbox>
        </div>
      </div>
      <div>
        {isConsentUpdatePermission && (
          <Button type="text" onClick={handleUpdateClick}>
            Update Consent
          </Button>
        )}
      </div>

      <div>
        <Table
          dataSource={dataSource}
          columns={dataColumns}
          pagination={false}
        />
      </div>
      <div>
        {isShowHistory && isConsentHistoryPermission && (
          <Button type="text" onClick={handleShowHistoryClick}>
            Show History
          </Button>
        )}
      </div>
    </div>
  );
};

export default Consent;
