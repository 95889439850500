import { AxiosRequestConfig } from "axios";
import { IConsentsData } from "@app/interfaces";
import { API_URL } from "../apiUrl";

export interface IGetConsentsRequest {
  params: {
    mdmId: string;
    countryCode: string;
  };
}

export interface IGetConsentsResponse {
  data: IConsentsData;
}

export const getConsents = ({ params }: IGetConsentsRequest): AxiosRequestConfig => {
  return {
    url: API_URL.USER_CONSENTS,
    params: params,
    method: "GET"
  };
};
