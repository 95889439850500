import { get, set, has } from "lodash";
import { Rule } from "antd/es/form";
import type { ISapAccount, IPhone } from "@msd-cex/sap-cdc-shared";
import { IOptionItem } from "@app/interfaces";
import { getUserPhones } from "@app/utils";
import { phoneTypes } from "@app/common/constants";
import { CustomFieldInputType } from "@app/components";
import { graduationYears } from "./dictionaries/graduationYears";
import { countries } from "./dictionaries/countries";
import { genders } from "./dictionaries/genders";
import { titles } from "./dictionaries/titles";
import { specialties } from "./dictionaries/specialties";
import { professions } from "./dictionaries/professions";

interface IUpdateMapperArgs {
  user: ISapAccount;
  submitData: ISapAccount;
  submitValues: Record<string, unknown>;
  value: unknown;
}

export interface IMappedUserFieldOptions {
  groupName?: string;
  name: string;
  updateName?: string;
  label: string;
  placeholder?: string;
  type: CustomFieldInputType;
  disabled?: boolean;
  options?: IOptionItem[];
  rules?: Rule[];
  mapper?: (user: ISapAccount) => string | undefined;
  updateMapper?: (args: IUpdateMapperArgs) => void;
  hideOnDetailsView?: boolean;
  minValue?: unknown;
  maxValue?: unknown;
}

interface IMappedUserField {
  group: string;
  fields: IMappedUserFieldOptions[];
}

const mapUserPhone = (phoneType: string, phones?: IPhone[] | Record<string, string>) => {
  const userPhones = getUserPhones(phones);

  return userPhones.find(phone => phone.type === phoneType)?.number;
};

const updateNumberValue = (fieldName: string, { submitData, value }: IUpdateMapperArgs) => {
  const numberValue = value ? parseInt(value as string) : null;

  set(submitData, fieldName, numberValue);
};

const updateBirthDate = ({ submitData, value }: IUpdateMapperArgs) => {
  const birthDate = value ? new Date(value as string) : null;

  if (birthDate) {
    set(submitData, "profile.birthDay", birthDate.getDate());
    set(submitData, "profile.birthMonth", birthDate.getMonth() + 1);
    set(submitData, "profile.birthYear", birthDate.getFullYear());
  } else {
    set(submitData, "profile.birthDay", null);
    set(submitData, "profile.birthMonth", null);
    set(submitData, "profile.birthYear", null);
  }
};

const updateUserPhone = (phoneType: string, { submitData, value }: IUpdateMapperArgs) => {
  const phoneNumber = value as string;
  let phones = get(submitData, "profile.phones", []) as IPhone[];

  if (phoneNumber) {
    let phone = phones.find(phone => phone.type === phoneType);

    if (!phone) {
      phone = {
        type: phoneType
      } as IPhone;

      phones.push(phone);
    }

    phone.number = phoneNumber;
  } else {
    phones = phones.filter(phone => phone.type !== phoneType);
  }

  if (phones.length > 0) {
    set(submitData, "profile.phones", phones);
  } else {
    set(submitData, "profile.phones", null);
  }
};

const updateWorkplace = ({ submitData, value }: IUpdateMapperArgs) => {
  const workplace = value ? (value as string) : null;

  if (workplace) {
    set(submitData, "data.organization.name", workplace);
  } else {
    set(submitData, "data.organization", null);
  }
};

const updateCustomer = ({ user, submitData, submitValues }: IUpdateMapperArgs) => {
  const customer = {
    specialty: get(user, "profile.specialty"),
    profession: get(user, "profile.profession"),
    license: get(user, "profile.license")
  };

  if (has(submitValues, "profile.specialty")) {
    const specialty = get(submitValues, "profile.specialty");

    if (specialty) {
      set(customer, "specialty", specialty);
    } else {
      set(customer, "specialty", null);
    }
  }

  if (has(submitValues, "profile.profession")) {
    const profession = get(submitValues, "profile.profession");

    if (profession) {
      set(customer, "profession", profession);
    } else {
      set(customer, "profession", null);
    }
  }

  if (has(submitValues, "profile.license.number")) {
    const licenseNumber = get(submitValues, "profile.license.number");

    if (licenseNumber) {
      set(customer, "license.number", licenseNumber);
    } else {
      set(customer, "license", null);
    }
  }

  const customerValues = Object.values(customer).filter(customerValue => customerValue);

  if (customerValues.length > 0) {
    set(submitData, "data.customer", customer);
  } else {
    set(submitData, "data.customer", null);
  }
};

export const mappedUserFields: IMappedUserField[] = [
  {
    group: "Primary Information",
    fields: [
      {
        name: "profile.email",
        updateName: "profile.email",
        label: "Email Address",
        type: "email",
        disabled: true
      },
      {
        name: "profile.title",
        updateName: "data.title",
        label: "Title",
        type: "select",
        hideOnDetailsView: true,
        options: titles
      },
      {
        name: "profile.firstName",
        updateName: "profile.firstName",
        label: "First Name",
        type: "text",
        hideOnDetailsView: true,
        rules: [{ required: true, message: "Required" }]
      },
      {
        name: "profile.middleName",
        updateName: "data.names.middleName",
        label: "Middle Name",
        type: "text",
        hideOnDetailsView: true
      },
      {
        name: "profile.lastName",
        updateName: "profile.lastName",
        label: "Last Name",
        type: "text",
        hideOnDetailsView: true,
        rules: [{ required: true, message: "Required" }]
      },
      {
        // options will be updated with data from the API in a different story.
        name: "profile.gender",
        updateName: "profile.gender",
        label: "Gender",
        type: "select",
        options: genders
      },
      {
        name: "profile.birthDate",
        updateName: "data.birthDate",
        label: "Birthdate",
        type: "date",
        minValue: new Date(1920, 0, 1),
        maxValue: new Date(),
        updateMapper: updateBirthDate
      },
      {
        name: "profile.languages",
        updateName: "profile.languages",
        label: "Language",
        type: "text",
        placeholder: "DE",
        disabled: true
      },

      //// HCP Details:
      {
        name: "profile.graduation.year",
        label: "Graduation Year",
        type: "select",
        options: graduationYears,
        mapper: (user: ISapAccount) => user.profile.graduation.year ? String(user.profile.graduation.year) : "",
        updateMapper: (args: IUpdateMapperArgs) => updateNumberValue("profile.education.endYear", args)
      },
      {
        name: "profile.graduation.institute",
        updateName: "profile.education.school",
        label: "Graduation Institution",
        type: "text"
      },

      //// Professional Details:
      {
        name: "profile.workplace.name",
        label: "Workplace Name",
        type: "text",
        updateMapper: updateWorkplace
      },
      {
        name: "profile.workplace.address",
        updateName: "profile.address",
        label: "Workplace Address",
        type: "text"
      },
      {
        name: "profile.workplace.city",
        updateName: "profile.city",
        label: "Workplace City",
        type: "text"
      },
      {
        name: "profile.workplace.postalCode",
        updateName: "profile.zip",
        label: "Workplace Postal Code",
        type: "text"
      },
      {
        name: "profile.workplace.state",
        updateName: "profile.state",
        label: "Workplace State",
        type: "text"
      },
      {
        name: "profile.workplace.country",
        updateName: "profile.country",
        label: "Country",
        type: "select",
        options: countries,
        mapper: (user: ISapAccount) => (user.profile.workplace.country ?? "").toUpperCase()
      },
      {
        groupName: "profile.phones",
        name: "profile.phones.phone",
        label: "Landline Phone",
        type: "phone",
        mapper: (user: ISapAccount) => mapUserPhone("phone", user.profile.phones),
        updateMapper: (args: IUpdateMapperArgs) => updateUserPhone(phoneTypes.PHONE, args)
      },
      {
        groupName: "profile.phones",
        name: "profile.phones.sms",
        label: "Mobile",
        type: "phone",
        mapper: (user: ISapAccount) => mapUserPhone("sms", user.profile.phones),
        updateMapper: (args: IUpdateMapperArgs) => updateUserPhone(phoneTypes.SMS, args)
      },
      {
        groupName: "profile.phones",
        name: "profile.phones.fax",
        label: "Fax",
        type: "phone",
        mapper: (user: ISapAccount) => mapUserPhone("fax", user.profile.phones),
        updateMapper: (args: IUpdateMapperArgs) => updateUserPhone(phoneTypes.FAX, args)
      },
      {
        name: "profile.profession",
        label: "Customer Type",
        type: "select",
        options: professions,
        updateMapper: updateCustomer
      },
      {
        name: "profile.specialty",
        label: "Main Specialty",
        type: "select",
        options: specialties,
        updateMapper: updateCustomer
      },
      {
        name: "profile.license.number",
        label: "License Number",
        type: "text",
        updateMapper: updateCustomer
      },
      {
        name: "profile.efnNumber",
        updateName: "data.externalIds.efnNumber",
        label: "EFN Number",
        type: "text"
      }
    ]
  },
  {
    group: "Administrative Details",
    fields: [
      {
        name: "systemData.countryCode",
        updateName: "data.countryCode",
        label: "Registration Country",
        type: "text",
        disabled: true
      },
      {
        name: "systemData.registrationService",
        updateName: "data.registrationService",
        label: "Registration Source",
        type: "text",
        disabled: true
      },
      {
        name: "systemData.lastLogin",
        updateName: "lastLogin",
        label: "Last Login (Date/Time)",
        type: "datetime",
        disabled: true
      },
      {
        name: "systemData.emailVerifiedDate",
        updateName: "data.emailVerifiedDate",
        label: "Email Verified (Date/Time)",
        type: "datetime",
        disabled: true
      },
      {
        name: "systemData.regSource",
        updateName: "regSource",
        label: "Referral",
        type: "text",
        disabled: true
      },
      {
        name: "profile.hcpStatus",
        updateName: "data.hcpStatus",
        label: "HCP Validation Status",
        type: "text",
        disabled: true
      },
      {
        name: "systemData.created",
        updateName: "created",
        label: "Created",
        type: "datetime",
        disabled: true
      },
      {
        name: "systemData.accountType",
        label: "Account type",
        type: "text",
        disabled: true
      }
    ]
  },
  {
    group: "Other Information",
    fields: [
      {
        name: "profile.mdmId",
        updateName: "data.mdmId",
        label: "MDM ID",
        type: "text",
        disabled: true
      },
      {
        name: "profile.iqviaId",
        updateName: "data.externalIds.iqviaId",
        label: "OneKey ID",
        type: "text",
        disabled: true
      },
      {
        name: "profile.uid",
        updateName: "uid",
        label: "UID",
        type: "text",
        disabled: true
      }
    ]
  }
];
