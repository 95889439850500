import { IOptionItem } from "@app/interfaces";

const graduationYears: IOptionItem[] = [];
const currentYear = new Date().getFullYear();

graduationYears.push({
  value: "",
  text: ""
});

for (let i = 1966; i <= currentYear; i++) {
  const year = String(i);

  graduationYears.push({
    value: year,
    text: year
  });
}

export { graduationYears };
