import { AxiosRequestConfig } from "axios";
import { IPermissions } from "@app/interfaces";
import { API_URL } from "../apiUrl";

export interface IGetPermissionsResponse {
  data: IPermissions;
}

export const getPermissions = (): AxiosRequestConfig => {
  return {
    url: API_URL.PERMISSIONS,
    method: "POST"
  };
};
