import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";
import { useErrorBoundary } from "react-error-boundary";
import ErrorIcon from "@app/assets/icons/error.svg";
import "./ErrorBoundaryFallback.scss";

export interface IErrorBoundaryFallbackProps {
  error: string;
}

const ErrorBoundaryFallback: React.FC<IErrorBoundaryFallbackProps> = ({ error }) => {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();
  const navigateToHome = () => {
    resetBoundary();
    navigate("/");
  };

  return (
    <div className="error__boundary">
      <ErrorIcon />
      <Typography.Title level={4}>{error}</Typography.Title>
      <Button type="primary" onClick={navigateToHome}>Back to home</Button>
    </div>
  );
};

export default ErrorBoundaryFallback;
