import { IOptionItem } from "@app/interfaces";

export const countries: IOptionItem[] = [
  { value: "", text: "" },
  { value: "AR", text: "Argentina" },
  { value: "AU", text: "Australia" },
  { value: "AT", text: "Austria" },
  { value: "BH", text: "Bahrain" },
  { value: "BE", text: "Belgium" },
  { value: "BR", text: "Brazil" },
  { value: "BG", text: "Bulgaria" },
  { value: "CA", text: "Canada" },
  { value: "CB", text: "Caribbean Island" },
  { value: "CL", text: "Chile" },
  { value: "CO", text: "Colombia" },
  { value: "CR", text: "Costa Rica" },
  { value: "HR", text: "Croatia" },
  { value: "CZ", text: "Czech" },
  { value: "DK", text: "Denmark" },
  { value: "DO", text: "Dominican Republic" },
  { value: "EG", text: "Egypt" },
  { value: "SV", text: "El Salvador" },
  { value: "FI", text: "Finland" },
  { value: "FR", text: "France" },
  { value: "DE", text: "Germany" },
  { value: "GR", text: "Greece" },
  { value: "HK", text: "Hong Kong" },
  { value: "HU", text: "Hungary" },
  { value: "IN", text: "India" },
  { value: "ID", text: "Indonesia" },
  { value: "IE", text: "Ireland" },
  { value: "IL", text: "Israel" },
  { value: "IT", text: "Italy" },
  { value: "JP", text: "Japan" },
  { value: "KR", text: "Korea" },
  { value: "KW", text: "Kuwait" },
  { value: "MY", text: "Malaysia" },
  { value: "MX", text: "Mexico" },
  { value: "NL", text: "Netherlands" },
  { value: "NZ", text: "New Zealand" },
  { value: "NO", text: "Norway" },
  { value: "OM", text: "Oman" },
  { value: "PA", text: "Panama" },
  { value: "PH", text: "Philippines" },
  { value: "PL", text: "Poland" },
  { value: "PT", text: "Portugal" },
  { value: "QA", text: "Qatar" },
  { value: "RO", text: "Romania" },
  { value: "SA", text: "Saudi Arabia" },
  { value: "RS", text: "Serbia" },
  { value: "SG", text: "Singapore" },
  { value: "SK", text: "Slovakia" },
  { value: "SI", text: "Slovenia" },
  { value: "ZA", text: "South Africa" },
  { value: "ES", text: "Spain" },
  { value: "SE", text: "Sweden" },
  { value: "CH", text: "Switzerland" },
  { value: "TW", text: "Taiwan" },
  { value: "TH", text: "Thailand" },
  { value: "TR", text: "Turkey" },
  { value: "AE", text: "United Arab Emirates" },
  { value: "UK", text: "United Kingdom" },
  { value: "US", text: "USA" },
  { value: "VN", text: "Vietnam" }
];
