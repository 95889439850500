import { get, set, cloneDeep } from "lodash";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { mappedUserFields } from "@app/common/userFields";

export const getSubmitData = (
  user: ISapAccount,
  formData: object,
  updatedValues: Record<string, unknown>
): ISapAccount => {
  const submitData = {} as ISapAccount;

  const submitValues = Object.keys(updatedValues).reduce((result, key) => {
    if (updatedValues[key] !== get(formData, key)) {
      result[key] = updatedValues[key];
    }

    return result;
  }, {} as Record<string, unknown>);

  const submitFields = Object.keys(submitValues);

  submitFields.forEach(fieldName => {
    const field = mappedUserFields.flatMap(({ fields }) => fields)
      .find(field => field.name === fieldName);

    if (!field) {
      console.error("Unable to find field:", fieldName);
      return;
    }

    const fieldValue = updatedValues[fieldName];
    const fieldUpdateName = field.updateName ?? field.name;

    if (field.groupName) {
      const submitDataGroup = get(submitData, field.groupName);

      if (!submitDataGroup) {
        const initialValues = cloneDeep(get(user, field.groupName, []));

        set(submitData, field.groupName, initialValues);
      }
    }

    if (field.updateMapper) {
      field.updateMapper({ user, submitData, submitValues, value: fieldValue });
    } else if (fieldValue) {
      set(submitData, fieldUpdateName, fieldValue);
    } else {
      set(submitData, fieldUpdateName, null);
    }
  });

  return submitData;
};
