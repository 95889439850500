import React from "react";
import { Users } from "@app/components";

const UsersPage: React.FC = () => {
  return (
    <Users />
  );
};

export default UsersPage;
