import { ChannelTypes, IConsent, IConsentsData, ILocalConsent } from "@app/interfaces";
import { uniqWith } from "lodash";
import { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { getConsentChannelInfo } from "@app/utils";

interface IConsentByChannelType {
  title: string;
  consents: ILocalConsent[];
}

export const getConsentsByChannelType = (
  userConsents?: IConsentsData,
  user?: ISapAccount
) => userConsents?.channels?.reduce((acc, channelType) => {
  const consentChannelTitle = getConsentChannelInfo(channelType).title;
  const consentsHistorical = userConsents?.consents?.consentsHistorical;
  const consentsStateful = userConsents.consents?.consentsStateful;
  const consentsNeverCaptured = userConsents.consents?.consentsNeverCaptured;

  const getConsentHistory = (channelValue: string | undefined): IConsent[] => {
    return consentsHistorical?.filter(consentHistorical =>
      consentHistorical.channelType === channelType && consentHistorical.channelValue === channelValue
    ) ?? [];
  };

  const statefulConsents = consentsStateful?.filter(consent => consent.channelType === channelType);
  const channelNeverCapturedConsents = consentsNeverCaptured
    .filter(consent => consent.channelType === channelType)
    ?.map((consent: IConsent): ILocalConsent => ({
      channelType,
      channelValue: consent.channelValue,
      title: consentChannelTitle,
      isShowCapture: true,
      history: []
    })) ?? [];

  if (statefulConsents?.length) {
    acc.push({
      title: consentChannelTitle,
      consents: [
        ...statefulConsents.map(consent => ({
          ...consent,
          title: consentChannelTitle,
          history: getConsentHistory(consent.channelValue)
        })),
        ...channelNeverCapturedConsents
      ]
    });

    return acc;
  }

  const defaultConsent: ILocalConsent = {
    channelType,
    title: consentChannelTitle,
    history: []
  };

  switch (channelType) {
    case ChannelTypes.EMAIL: {
      defaultConsent.isShowCapture = true;
      defaultConsent.channelValue = user?.profile.email?.toLowerCase();
      break;
    }
    case ChannelTypes.POSTAL: {
      defaultConsent.channelValue = "Customer";
      defaultConsent.optIn = true;
      break;
    }
    case ChannelTypes.TRANSFER_OF_VALUE: {
      defaultConsent.channelValue = "Customer";
      defaultConsent.optIn = false;
      break;
    }
    default: {
      break;
    }
  }

  const consents = uniqWith([
    defaultConsent,
    ...channelNeverCapturedConsents
  ], (a, b) => a.channelType === b.channelType && a.channelValue === b.channelValue);
  const hasConsentsWithValue = consents.some(c => !!c.channelValue);

  acc.push({
    title: consentChannelTitle,
    consents: hasConsentsWithValue ? consents.filter(c => !!c.channelValue) : consents
  });

  return acc;
}, [] as IConsentByChannelType[])
  .filter(({ consents }) => !!consents.length);
