import React from "react";
import { Rule } from "antd/es/form";
import { IOptionItem } from "@app/interfaces";
import CustomDateField from "./CustomDateField";
import CustomInputField from "./CustomInputField";
import CustomPhoneField from "./CustomPhoneField";
import CustomSelectField from "./CustomSelectField";

export type CustomFieldInputType = "text" | "email" | "date" | "datetime" | "select" | "number" | "phone";

export interface ICustomFieldProps {
  type: CustomFieldInputType;
  name: string;
  label: string;
  disabled?: boolean;
  options?: IOptionItem[];
  rules?: Rule[];
  value: unknown;
  minValue?: unknown;
  maxValue?: unknown;
  placeholder?: string;
}

const CustomField: React.FC<ICustomFieldProps> = props => {
  let content;

  switch (props.type) {
    case "date":
    case "datetime":
      content = (
        <CustomDateField {...props} />
      );
      break;

    case "select":
      content = (
        <CustomSelectField {...props} />
      );
      break;

    case "phone":
      content = (
        <CustomPhoneField {...props} />
      );
      break;

    default:
      content = (
        <CustomInputField {...props} />
      );
      break;
  }

  return (
    content
  );
};

export default CustomField;
