import React from "react";
import { Typography } from "antd";
import { ILocalConsent } from "@app/interfaces";
import { getConsentChannelInfo } from "@app/utils";
import "./ConsentNotFound.scss";

const { Text } = Typography;

interface IConsentNotFoundProps extends React.PropsWithChildren {
  consent: ILocalConsent;
}

const getChannelText = (channelDescription: string) => `
  Impossible to capture consent: no channel value is found in user profile,
  please specify the value in ${channelDescription} field and try again.
`;

const ConsentNotFound: React.FC<IConsentNotFoundProps> = props => {
  const { consent } = props;

  const channelDescription = getConsentChannelInfo(consent.channelType).description;
  const channelText = getChannelText(channelDescription);

  return (
    <div className="ConsentNotFound">
      <Text>
        {channelText}
      </Text>
      {props.children}
    </div>
  );
};

export default ConsentNotFound;
