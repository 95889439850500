import React from "react";
import { Outlet } from "react-router-dom";
import AppPageHeader from "./AppPageHeader";

const AppPage: React.FC = () => {
  return (
    <>
      <AppPageHeader />
      <Outlet />
    </>
  );
};

export default AppPage;
