import { storageKeys } from "@app/common/constants";

export const hasTokenExpired = (): boolean => {
  const tokenId = localStorage.getItem(storageKeys.TOKEN_ID);

  if (!tokenId) {
    return true;
  }

  try {
    const { exp } = JSON.parse(atob(tokenId.split(".")[1])) as { exp: number };

    return exp * 1000 < Date.now();
  } catch (e) {
    return true;
  }
};
