import { AxiosRequestConfig } from "axios";
import { IConsentUpdate } from "@app/interfaces";
import { API_URL } from "../apiUrl";

export interface IUpdateConsentRequest {
  data: {
    mdmId: string;
    countryCode: string;
    consents: IConsentUpdate[];
  };
}

export interface IUpdateConsentResponse {
  data: {
    error?: string;
  };
}

export const updateConsent = ({ data }: IUpdateConsentRequest): AxiosRequestConfig => {
  return {
    url: API_URL.USER_UPDATE_CONSENT,
    method: "POST",
    data: data
  };
};
