import { LOGIN_URI, LOGOUT_URI, storageKeys } from "@app/common/constants";

export const useAuth = () => {
  const redirect = (url: string, saveLocation: boolean = false) => {
    if (saveLocation) {
      const currentLocation = `${window.location.pathname}${window.location.search}`;
      localStorage.setItem(storageKeys.REDIRECT_LOCATION, currentLocation);
    }

    localStorage.removeItem(storageKeys.TOKEN_ID);
    localStorage.removeItem(storageKeys.ACCESS_TOKEN_ID);

    window.location.href = url;
  };

  const logout = (saveLocation: boolean = false) => {
    redirect(LOGOUT_URI, saveLocation);
  };

  const login = (saveLocation: boolean = false) => {
    redirect(LOGIN_URI, saveLocation);
  };

  return {
    logout,
    login
  };
};
