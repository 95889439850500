import React from "react";
import { Typography, Button } from "antd";
import { ILocalConsent } from "@app/interfaces";
import { usePermissions } from "@app/hooks";
import { permissions } from "@app/common/constants";
import { getConsentChannelInfo } from "@app/utils";
import "./ConsentNotCaptured.scss";

const { Text } = Typography;

interface IConsentNotCapturedProps extends React.PropsWithChildren {
  consent: ILocalConsent;
  onCapture: (consent: ILocalConsent) => void;
}

const ConsentNotCaptured: React.FC<IConsentNotCapturedProps> = props => {
  const { consent, onCapture } = props;

  const { hasPermission } = usePermissions();
  const isConsentCapturePermission = hasPermission(permissions.CONSENT_CAPTURE);

  const channelDescription = getConsentChannelInfo(consent.channelType).description;

  const handleCaptureClick = () => {
    onCapture(consent);
  };

  return (
    <div className="ConsentNotCaptured">
      <Text>
        {channelDescription} is not captured for {consent.channelValue}
      </Text>

      {isConsentCapturePermission && (
        <Button type="text" onClick={handleCaptureClick}>
          Capture Consent
        </Button>
      )}
    </div>
  );
};

export default ConsentNotCaptured;
