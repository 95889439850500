import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams, Link, Outlet, matchRoutes } from "react-router-dom";
import { Menu, Button, Flex, Layout, Typography, Space, Result } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { Avatar } from "@mds/merck-design-system";
import { routes } from "@app/common/routes";
import { titles } from "@app/common/dictionaries/titles";
import { permissions } from "@app/common/constants";
import { useFetch, usePermissions } from "@app/hooks";
import * as api from "@app/services/api";
import { Loader } from "@app/components";
import type { UserContext } from "@app/components";
import { getFullName, getErrorMessage } from "@app/utils";
import { IConsentsData, IBackUrlState } from "@app/interfaces";
import { AppContext } from "@app/store";
import "./UserPage.scss";

const { Content, Header } = Layout;
const { Text } = Typography;

const tabItems = [
  { label: "Profile", key: routes.userDetails.profile },
  { label: "Consent", key: routes.userDetails.consents }
];

const UserPage = () => {
  const { uid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const countryCode = appContext.activeFlow?.id ?? "";
  const [user, setUser] = useState<ISapAccount | undefined>(undefined);
  const [userConsents, setUserConsents] = useState<IConsentsData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const getUser = useFetch<api.IGetUserRequest>(api.getUser);
  const getConsents = useFetch<api.IGetConsentsRequest>(api.getConsents);
  const [selectedTab, setSelectedTab] = useState(routes.userDetails.profile);

  const { hasPermission } = usePermissions();
  const isProfileUpdatePermission = hasPermission(permissions.PROFILE_UPDATE);

  const onTabClick = ({ key }: { key: string }) => {
    setSelectedTab(key);
    navigate(key, { state: location.state });
  };

  const fetchConsents = async() => {
    setLoading(true);

    const handleError = (error: unknown) => {
      const errorMessage = getErrorMessage(error);

      console.error("Unable to load user consents:", errorMessage);
    };

    try {
      if (user?.profile.mdmId) {
        const getConsentsResponse = await getConsents<api.IGetConsentsResponse>({
          params: {
            mdmId: user.profile.mdmId,
            countryCode: countryCode
          }
        });

        setUserConsents(getConsentsResponse.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUser = async(request: api.IGetUserRequest) => {
    setLoading(true);

    const handleError = (error: unknown) => {
      const errorMessage = getErrorMessage(error);

      console.error("Unable to load user data:", errorMessage);
    };

    try {
      const getUserResponse = await getUser<api.IGetUserResponse>(request);
      const user = getUserResponse.data;

      if (user.profile.mdmId) {
        const getConsentsResponse = await getConsents<api.IGetConsentsResponse>({
          params: {
            mdmId: user.profile.mdmId,
            countryCode: countryCode
          }
        });

        setUserConsents(getConsentsResponse.data);
      }

      setUser(user);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!uid) {
      return;
    }

    void fetchUser({
      params: {
        uid
      }
    });
  }, [uid]);

  useEffect(() => {
    const isConsentTab = matchRoutes([
      { path: `${routes.users}/:id/${routes.userDetails.consents}` }
    ], location);

    if (isConsentTab) {
      setSelectedTab(routes.userDetails.consents);
    }
  }, []);

  if (loading) {
    return (
      <Loader>
        Loading user ...
      </Loader>
    );
  }

  if (!user) {
    return (
      <Result
        status="error"
        title="Unable to load user"
      />
    );
  }

  const title = titles.find(item => (item.value ?? item.text) === user.profile.title)?.text;

  const fullName = getFullName([
    title,
    user.profile.firstName,
    user.profile.middleName,
    user.profile.lastName
  ]);

  const getBackUrl = () => {
    if (location.state satisfies IBackUrlState) {
      return location.state.backUrl;
    }

    return `/${routes.users}`;
  };

  const handleConsentUpdated = () => {
    void fetchConsents();
  };

  return (
    <>
      <Header className="ap-user-page__header">
        <Flex justify="space-between">
          <Space size="middle">
            <Link to={getBackUrl()}>
              <LeftOutlined />
            </Link>
            <Avatar />
            <Typography>
              <Text>{fullName}</Text>
            </Typography>
          </Space>

          {isProfileUpdatePermission && (
            <Space>
              <Link to={routes.userDetails.edit} state={location.state}>
                <Button type="primary">
                  Edit
                </Button>
              </Link>
            </Space>
          )}
        </Flex>
      </Header>

      <Content>
        <Menu onClick={onTabClick} selectedKeys={[selectedTab]} mode="horizontal" items={tabItems} />
        <Outlet
          context={
            {
              user,
              userConsents,
              onConsentUpdated: handleConsentUpdated
            } satisfies UserContext
          }
        />
      </Content>
    </>
  );
};

export default UserPage;
